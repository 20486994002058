<template>
  <div class="add-rule-dialog">
    <pageDialog :isShowFooter="true" width="500px" :title="formData&&formData.id?'编辑指标':'新增指标'" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible">
      <el-form label-position="left" ref="ruleFormRef" :model="ruleForm" label-width="100px"
        class="demo-ruleForm" label-suffix="：" :rules="rules">
        <el-form-item label="角色" prop="roleId">
          <el-select v-model="ruleForm.roleId" placeholder="请选择" @change="changeRole">
            <el-option v-for="item in roleTypeList" :key="item.roleId" :label="item.roleName" :value="item.roleId" />
          </el-select>
        </el-form-item>
        <el-form-item label="指标类型" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择" @change="changeType">
            <el-option v-for="item in typeList" :key="item.id" :label="item.label" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="结果值" prop="resultType">
          <el-select v-model="ruleForm.resultType" placeholder="请选择" :disabled="!ruleForm.type" @change="changeResultType">
            <el-option v-for="item in ruleForm.type==1?resultList:resultLists" :key="item.id" :label="item.label" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="指标名称" prop="resultId" v-if="ruleForm.resultType==1">
          <el-select v-model="ruleForm.resultId" placeholder="请选择" @change="changeIndicators" :disabled="!ruleForm.roleId">
            <el-option v-for="item in indicators" :key="item.resultId" :label="item.name" :value="item.resultId" />
          </el-select>
        </el-form-item>
        <el-form-item label="指标名称" prop="name" v-else>
          <el-input v-model="ruleForm.name" placeholder="请输入" :disabled="!ruleForm.resultType"/>
        </el-form-item>
        <el-form-item label="指标说明" prop="remark">
          <el-input v-model="ruleForm.remark" :autosize="{ minRows: 3, maxRows: 10 }" type="textarea" placeholder="请输入" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="save">
          <el-button type="primary" size="small" @click="handleSaveClick(ruleFormRef)">保存</el-button>
        </div>
      </template>
    </pageDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed ,watch} from 'vue'
import pageDialog from '@/components/page-dialog/page-dialog.vue'
import { rules } from '../../../category-tagetory/config/index'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import {createCheckIndicators,editCheckIndicators,getSystemIndicators} from '@/service/main/target'
import {getAllRoleList} from '@/service/main/staff'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  components: {
    pageDialog
  },
  
  emits: ['changeDialogVisible','refreshPage'],
  setup(props, { emit }) {
    
    const store = useStore()
    const roleTypeList = ref([])
    const resultList = reactive(
      [{
        id:1,
        label:'系统计算'
      },{
        id:2,
        label:'手动录入'
      }]
    )
    const resultLists = reactive(
      [{
        id:2,
        label:'手动录入'
      }]
    )
    const typeList = reactive(
      [{
        id:1,
        label:'量化指标'
      },{
        id:2,
        label:'加分项'
      },{
        id:3,
        label:'扣分项'
      }]
    )
    const indicators = ref([])
    const changeDialogVisible = flag => {
      emit('changeDialogVisible', flag)
      if (!flag) {
        ruleForm.roleId=''
        ruleForm.type=''
        ruleForm.resultType=''
        ruleForm.resultId=''
        ruleForm.name=''
        ruleForm.remark=''
        ruleForm.id=''
        indicators.value=[]
      }
    }
    const ruleForm = reactive({
      roleId: '',
      type: '',
      resultType: '',
      resultId:'',
      name:'',
      remark:'',
    })
    const changeType = (e) =>{
      if (e==1) {
      }else{
        ruleForm.resultType=''
      }
    }
    const changeResultType = (e) =>{
      if (e==1) {
        ruleForm.name=''
      }else{
        ruleForm.name=''
        ruleForm.resultId=''
      }
    }
    const changeRole = async(e) =>{
      ruleForm.resultId=''
      indicators.value=[]
      let res = await getSystemIndicators(e)
      indicators.value=res.data
    }
    const changeIndicators = (e) =>{
      let array = indicators.value.filter(item=>{return item.resultId==e})
      if (array&&array.length) {
        ruleForm.name=array[0].name
      }
    }

    watch(() => props.formData, (n) => {
      if (n != null) {
        changeRole(n.roleId)
        ruleForm.id = n.id
        ruleForm.roleId=n.roleId
        ruleForm.type=n.type
        ruleForm.resultType=n.resultType
        ruleForm.resultId=n.resultType==1?n.resultId:''
        ruleForm.name=n.name
        ruleForm.remark=n.remark
      }
    }, {
      deep: true,
      immediate: true
    })
    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async(valid, fields) => {
        if (valid) {
          let params = {
            roleId: ruleForm.roleId,
            type: ruleForm.type,
            resultType: ruleForm.resultType,
            resultId:ruleForm.resultType==1?ruleForm.resultId:'',
            name:ruleForm.name,
            remark:ruleForm.remark,
          }
          if (props.formData&&props.formData.id) {
            let res = await editCheckIndicators({...params,id:ruleForm.id})
            if (res.code == 0) {
                ElMessage({
                  message: '编辑成功!',
                  type: 'success',
                })
                changeDialogVisible(false)
                emit('refreshPage')
            } else {
              ElMessage.error('编辑失败，请重试!')
            }
          }else{
            let res = await createCheckIndicators(params)
            if (res.code == 0) {
                ElMessage({
                  message: '新建成功!',
                  type: 'success',
                })
                changeDialogVisible(false)
                emit('refreshPage')
            } else {
              ElMessage.error('新建失败，请重试!')
            }
          }
          
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const ruleFormRef = ref()
    const ruleList = computed(() => store.state.target.ruleList)
    const getRoleList = async() =>{
      const roleRes = await getAllRoleList()
      roleTypeList.value = roleRes.data
    }
    getRoleList()


    return {
      resultList,
      resultLists,
      typeList,
      ruleList,
      changeDialogVisible,
      ruleFormRef,
      ruleForm,
      handleSaveClick,
      roleTypeList,
      changeType,
      changeResultType,
      changeRole,
      indicators,
      changeIndicators,
      rules
    }

  }
})
</script>

<style scoped lang="less">
.add-rule-dialog {
  &:deep(.el-select .el-input__inner){
    width: 300px;
  }
  &:deep(.el-input__inner){
    width: 300px;
  }
  &:deep(.el-textarea__inner){
    width: 300px;
  }
}
</style>




