<template>
  <div class="shop-target">
    <el-row :gutter="20">
      <el-col :span="4">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>角色</span>
            </div>
          </template>
          <div v-for="item in roleTypeList" :key="item" :class="actived==item.roleId?'color':'text'" @click="tapType(item.roleId)">{{ item.roleName }}<span v-if="item.roleId==0">({{allTotal}})</span></div>
          <template #footer>Footer content</template>
        </el-card>
      </el-col>
      <el-col :span="20">
        <div class="add-button">
          <el-button @click="handleAddRuleClick()" type="primary" v-has="'target:add'">新增</el-button>
          <el-button @click="handleRemoveClick" type="danger" :disabled="!(multipleSelection&&multipleSelection.length)" v-has="'target:delete'">删除</el-button>
        </div>
        <el-table
          ref="multipleTableRef"
          :data="list"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column label="角色" prop="role"/>
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="指标类型" prop="type">
            <template #default="scope">
              <p v-if="scope.row.type==1">量化指标</p>
              <p v-if="scope.row.type==2">加分项</p>
              <p v-if="scope.row.type==3">扣分项</p>
            </template>
          </el-table-column>
          <el-table-column label="指标说明" prop="remark"></el-table-column>
          <el-table-column label="结果值" prop="resultType">
            <template #default="scope">
              <p v-if="scope.row.resultType==1">系统计算</p>
              <p v-if="scope.row.resultType==2">手动录入</p>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button size="small" type="primary" @click="handleEdit(scope.row)" v-has="'target:edit'"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          style="padding-top: 20px;"
          :current-page="params.current" :page-size="params.size" :page-sizes="[10, 20, 30]"
          layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
      </el-col>
    </el-row>
    <addIndicatorDialog @changeDialogVisible="changeDialogVisible" :formData="formData" :dialogVisible="dialogVisible" @refreshPage="refreshPage"></addIndicatorDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import titles from '../base-ui/titles/titles'
import { useRouter } from 'vue-router'
import cache from '@/utils/cache'
import addIndicatorDialog from '../add-indicator-dialog/index'
import { ElMessage } from 'element-plus'
import { popup } from '@/utils/pop-up'
import {batchDelete,getCheckIndicators} from '@/service/main/target'
export default defineComponent({
  props: {
    list: {
      type: Array,
      required: true
    },
    actived: {
      type: Number,
      required: true
    },
    allTotal: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    roleTypeList:{
      type: Array,
      required: true
    }
  },
  components: {
    titles,
    addIndicatorDialog
  },
  emits: ['changeType', 'handleSizeChange','handleCurrentChange','refreshPage'],
  setup(props, { emit }) {
    const router = useRouter()
    const dialogVisible = ref(false)
    const multipleSelection = ref([])
    // const roleTypeList = ref([])
    const params = reactive({
      current:1,
      size:10
    })
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
      if (!flag) {
        formData.value=null
      }
    }
    const refreshPage = () =>{
      emit('refreshPage')
    }

    const handleAddRuleClick = () => {
      changeDialogVisible(true)
    }
    const handleRemoveClick = () =>{
      popup('是否确认删除?', async() => {
        let res = await batchDelete({ids:multipleSelection.value})
        if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })
          emit('refreshPage')
        } else {
          ElMessage({
            message: res.msg,
            type: 'warning',
          })
        }
      })
    }
    const tapType = (id) =>{
      emit('changeType',id)
    }
    const handleSizeChange = pagesize => {
      params.size = pagesize
      emit('handleSizeChange',pagesize)
    }

    const handleCurrentChange = page => {
      params.current = page
      emit('handleCurrentChange',page)
    }
    const handleSelectionChange = (val) => {
      multipleSelection.value = val.map(item=>{
        return item.id
      })
    }
    const formData = ref(null)
    const handleEdit = async(item) =>{
      let res = await getCheckIndicators(item.id)
      formData.value=res.data
      changeDialogVisible(true)
    }

    return {
      dialogVisible,
      handleAddRuleClick,
      changeDialogVisible,
      // roleTypeList,
      tapType,
      params,
      handleSizeChange,
      handleCurrentChange,
      multipleSelection,
      handleSelectionChange,
      handleRemoveClick,
      refreshPage,
      handleEdit,
      formData
    }

  }
})
</script>

<style scoped lang="less">
.shop-target {
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    font-size: 14px;
    margin-bottom: 25px;
    cursor: pointer;
  }
  .color{
    font-size: 14px;
    margin-bottom: 25px;
    color: #7948ea;
    cursor: pointer;
  }
  .box-card {
    // width: 480px;
  }
  .add-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  &:deep(.el-card){
    min-height: 550px;
  }
  &:deep(.el-table) {
    // font-size: 12px;
    thead.is-group th.el-table__cell{
        background-color: rgba(239, 239, 239, 1) !important;
        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }
    .el-checkbox {
      // height: auto !important;
    }


    .el-table__header-wrapper {
      
      .el-table__cell {

        background-color: rgba(239, 239, 239, 1);
        // padding: 9px 0 !important;
        // border:1px solid rgb(223, 223, 223);

        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }

    }
  }
}
</style>




