<template>
  <div class="titles">
    <div>排名</div>
    <div>门店</div>
    <div>
      <template v-if="category == 0">
        店长
      </template>
      <template v-else>
        姓名
      </template>


    </div>
    <div class="zhibiao">指标</div>
    <div>1月</div>
    <div>2月</div>
    <div>3月</div>
    <div>4月</div>
    <div>5月</div>
    <div>6月</div>
    <div>7月</div>
    <div>8月</div>
    <div>9月</div>
    <div>10月</div>
    <div>11月</div>
    <div>12月</div>
    <div class="sum">合计</div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    category: {
      type: Number,
      default: -1
    }
  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.titles {
  display: flex;
  height: 42px;
  line-height: 42px;
  background-color: rgb(239, 239, 239);
  font-size: 12px;

  .zhibiao {
    flex: 2;
  }

  &>div {
    flex: 1;
    text-align: center;
    font-weight: bold;
  }

  .sum {
    background-color: rgb(255, 214, 214);
  }
}
</style>




