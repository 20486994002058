<template>
  <div class="category-tagetory">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="category-tagetory-content">
      <topContent :activeName="activeName" @refreshPage="refreshPage" @handleSearchClick="handleSearchClick"></topContent>
      <div class="add-button" v-if="activeName == 'second'">
        <el-button @click="handleTargetClick(0)"  type="primary" v-has="'staff:examine:add'">发起考核</el-button>
      </div>
      <div class="add-button" v-if="activeName == 'third'">
        <el-button @click="handleTargetClick(3)" type="primary" v-has="'examine:table:add'">新增考评表</el-button>
      </div>

      <div class="tabs">
        <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-click="changeTab">
          <el-tab-pane label="考核结果" name="first">
            <div v-if="targetList.results&&targetList.results.length">
              <assessmentResults :list="targetList.results"></assessmentResults>
              <el-pagination @size-change="handleResultsSizeChange" @current-change="handleResultsCurrentChange"
              style="padding-top: 20px;"
              :current-page="params.resultsCurrent" :page-size="params.resultsSize" :page-sizes="[10, 20, 30]"
              layout="total, sizes, prev, pager, next, jumper" :total="params.resultsTotal"></el-pagination>
            </div>
            <div class="empty" v-else>
              <el-empty description="暂无考核结果" />
            </div>
          </el-tab-pane>
          <el-tab-pane  label="已发起考核" name="second">
            <div v-if="targetList.examine&&targetList.examine.length">
              <resultsList :list="targetList.examine"></resultsList>
              <el-pagination @size-change="handleExamineSizeChange" @current-change="handleExamineCurrentChange"
              style="padding-top: 20px;"
              :current-page="params.examineCurrent" :page-size="params.examineSize" :page-sizes="[10, 20, 30]"
              layout="total, sizes, prev, pager, next, jumper" :total="params.examineTotal"></el-pagination>
            </div>
            <div class="empty" v-else>
              <el-empty description="暂无数据" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="考核表" name="third">
            <kpiList :list="targetList.kpiData" @refreshPage="refreshPage"></kpiList>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              style="padding-top: 20px;"
              :current-page="params.kpiCurrent" :page-size="params.kpiSize" :page-sizes="[10, 20, 30]"
              layout="total, sizes, prev, pager, next, jumper" :total="params.kpiTotal"></el-pagination>
          </el-tab-pane>
          <el-tab-pane label="指标库" name="fourth">
            <indicatorLibrary :roleTypeList="roleTypeList" :list="targetList.indicatorData" :actived="actived" @changeType="changeType" :allTotal="allTotal" @handleSizeChange="handleSizeChange1" @handleCurrentChange="handleCurrentChange1" :total="params.indicatorTotal" @refreshPage="refreshPage"></indicatorLibrary>
          </el-tab-pane>
        </el-tabs>
      </div>

    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import topContent from './cpns/top-content/top-content.vue'
import assessmentResults from './cpns/assessment-results/assessment-results.vue'
import indicatorLibrary from './cpns/indicator-library/indicator-library.vue'
import resultsList from './cpns/results-list/results-list.vue'
import kpiList from './cpns/kpi-list/kpi-list.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getCheckTableList, getCheckIndicatorsList, getCheckList, getTargetXiaoshoue, getTargetXukasxu, getTargetXinkelv } from '@/service/main/target'
import dayjs from 'dayjs'
import cache from '@/utils/cache'
import {getAllRoleList} from '@/service/main/staff'

export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    topContent,
    assessmentResults,
    indicatorLibrary,
    resultsList,
    kpiList

  },
  setup() {
    const router = useRouter()
    const stoe = useStore()
    const year = ref(dayjs().format('YYYY'))
    const actived = ref(0)
    const allTotal = ref(0)
    const roleTypeList = ref([])

    const activeName = ref('first')
    const handleTargetClick = (type) => {
      router.push({
        path: '/main/target/addCategoryTarget',
        query: {
          type
        }
      })
    }

    const targetList = reactive({
      results: [],
      examine: [],
      kpiData: [],
      indicatorData: []
    })
    const params = reactive({
      kpiCurrent:1,
      kpiSize:10,
      kpiTotal:0,
      kpiName:'',
      indicatorCurrent:1,
      indicatorSize:10,
      indicatorTotal:0,
      indicatorName:'',
      examineCurrent:1,
      examineSize:10,
      examineTotal:0,
      resultsCurrent:1,
      resultsSize:10,
      resultsTotal:0,
    })

    const refreshPage = (date) => {
      initPage()
    }
    const changeTab = (e) =>{
      initPage()
    }
    const handleSearchClick = (e) =>{
      if (activeName.value=='third') {
        params.kpiName = e
      }else{
        params.indicatorName = e
      }
      initPage()
    }
    const changeType = (e) =>{
      actived.value=e
      initPage()
    }
    const getResultsList = async () => {
      let data = {
        current:params.kpiCurrent,
        size:params.kpiSize,
        year:year.value,
        status:3
      }
      let res = await getCheckList(data)
      targetList.results = res.data.list
      params.resultsTotal = Number(res.data.total)
    }
    const getExamineList = async () => {
      let data = {
        current:params.kpiCurrent,
        size:params.kpiSize,
        year:year.value
      }
      let res = await getCheckList(data)
      targetList.examine = res.data.list
      params.examineTotal = Number(res.data.total)
    }
    const getkpiList = async () => {
      let data = {
        current:params.kpiCurrent,
        size:params.kpiSize,
        name:params.kpiName||undefined
      }
      let res = await getCheckTableList(data)
      targetList.kpiData = res.data.list
      params.kpiTotal = Number(res.data.total)
    }
    const getIndicatorData = async () => {
      let data = {
        current:params.indicatorCurrent,
        size:params.indicatorSize,
        roleId:actived.value==0?undefined:actived.value,
        name:params.indicatorName||undefined
      }
      let res = await getCheckIndicatorsList(data)
      targetList.indicatorData = res.data.list
      if (actived.value==0) {
        allTotal.value = Number(res.data.total)
      }
      params.indicatorTotal = Number(res.data.total)
    }
    const handleSizeChange = pagesize => {
      params.kpiSize = pagesize
      initPage()
    }

    const handleCurrentChange = page => {
      params.kpiCurrent = page
      initPage()
    }
    const handleSizeChange1 = (pagesize) => {
      params.indicatorSize = pagesize
      initPage()
    }
    const handleCurrentChange1 = (page) => {
      params.indicatorCurrent = page
      initPage()
    }
    const handleExamineSizeChange = (pagesize) => {
      params.examineSize = pagesize
      initPage()
    }
    const handleExamineCurrentChange = (page) => {
      params.examineCurrent = page
      initPage()
    }
    const handleResultsSizeChange = (pagesize) => {
      params.resultsSize = pagesize
      initPage()
    }
    const handleResultsCurrentChange = (page) => {
      params.resultsCurrent = page
      initPage()
    }
    const initPage = async () => {
      year.value = dayjs(new Date()).format('YYYY')
      // let targetYear = cache.getCache('targetYear')
      // if (targetYear == undefined) {
      //   year.value = dayjs(new Date()).format('YYYY')
      //   cache.setCache('targetYear', year)
      // } else {
      //   year.value = targetYear
      // }
      switch (activeName.value) {
        case 'first':
          getResultsList()
          break;
        case 'second':
          getExamineList()
          break;
        case 'third':
          getkpiList()
          break;
        case 'fourth':
          getIndicatorData()
          break;
      
      }
    }
    initPage()

    const getRoleList = async() =>{
      const roleRes = await getAllRoleList()
      roleTypeList.value = [{roleId:0,roleName:'全部角色'},...roleRes.data]
    }
    getRoleList()

    return {
      breadcrumbList,
      activeName,
      handleTargetClick,
      year,
      targetList,
      refreshPage,
      changeTab,
      handleSizeChange,
      handleCurrentChange,
      params,
      handleSearchClick,
      actived,
      changeType,
      allTotal,
      handleSizeChange1,
      handleCurrentChange1,
      handleExamineSizeChange,
      handleExamineCurrentChange,
      handleResultsSizeChange,
      handleResultsCurrentChange,
      roleTypeList
    }

  }
})
</script>

<style scoped lang="less">
.category-tagetory {
  min-width: 980px;

  .add-button {
    position: absolute;
    right: 14px;
    top: 82px;
    z-index: 99;
  }

  .tabs {
    margin-top: 20px;
  }

  .category-tagetory-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
    min-height: 750px;
    position: relative;
  }
}
</style>




