<template>
  <div class="shop-target">
    <el-table :data="list" style="width: 100%">
      <el-table-column label="名称" prop="name" />
      <el-table-column label="周期类型" prop="cycleType">
        <template #default="scope">
          <p>{{ scope.row.cycleType==1?'月度':'' }}</p>
        </template>
      </el-table-column>
      <el-table-column label="指标" prop="indicatorName" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="small" type="primary" @click="handleEdit(scope.row)" v-has="'examine:table:edit'"
            >编辑</el-button
          >
          <el-button
            size="small"
            @click="handleEdit(scope.row,'check')"
            v-has="'examine:table:check'"
            >查看</el-button
          >
          <el-button
            size="small"
            type="danger"
            @click="handleDelete(scope.row)"
            v-has="'examine:table:delete'"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref,computed } from 'vue'
import titles from '../base-ui/titles/titles'
import { useRouter } from 'vue-router'
import { deleteCheckTable } from '@/service/main/target'
import { ElMessage } from 'element-plus'
import { popup } from '@/utils/pop-up'
export default defineComponent({
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  components: {
    titles
  },
  emits: ['refreshPage'],
  setup(props, { emit }) {
    const router = useRouter()

    const search = ref('')
    const filterTableData = computed(() =>
      props.list.filter(
        (data) =>
          !search.value ||
          data.name.toLowerCase().includes(search.value.toLowerCase())
      )
    )

    const handleDelete = (item) =>{
      popup('是否确认删除?', async() => {
        let res = await deleteCheckTable(item.id)
        if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })
          emit('refreshPage')
        } else {
          ElMessage({
            message: res.msg,
            type: 'warning',
          })
        }
      })
    }
    const handleEdit = (item,isCheck) =>{
      router.push({
        path: '/main/target/addCategoryTarget',
        query: {
          id: item.id,
          type: 3,
          isCheck
        }
      })
    }

    return {
      search,
      filterTableData,
      handleDelete,
      handleEdit
    }

  }
})
</script>

<style scoped lang="less">
.shop-target {
  &:deep(.el-table) {
    // font-size: 12px;
    thead.is-group th.el-table__cell{
        background-color: rgba(239, 239, 239, 1) !important;
        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }
    .el-checkbox {
      // height: auto !important;
    }


    .el-table__header-wrapper {
      
      .el-table__cell {

        background-color: rgba(239, 239, 239, 1);
        // padding: 9px 0 !important;
        // border:1px solid rgb(223, 223, 223);

        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }

    }
  }
}
</style>