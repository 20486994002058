<template>
  <div class="top-content">
    <div class="left">
      <el-date-picker v-if="activeName=='first'||activeName=='second'" @change="handleYearChange" v-model="ruleForm.year" type="year" placeholder="选择年份" />
      <el-input v-if="activeName=='third'" v-model="ruleForm.kpiName" clearable style="width: 250px;margin-right: 10px;"  placeholder="请输入考评表名称" />
      <el-input v-if="activeName=='fourth'" v-model="ruleForm.indicatorName" clearable style="width: 250px;margin-right: 10px;" placeholder="请输入指标名称" />
      <el-button v-if="activeName=='third'||activeName=='fourth'" @click="handleSearchClick" type="primary">搜索</el-button>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { getBelongShop } from '@/service/main/staff'
import { COMPANY_ID } from '@/common/constant'
import dayjs from 'dayjs'
import cache from '@/utils/cache'
export default defineComponent({
  props: {
    activeName: {
      type: String,
      required: true
    }
  },
  components: {

  },
  emits: ['refreshPage','handleSearchClick'],
  setup(props, { emit }) {
    const router = useRouter()
    const handleTargetClick = () => {
      router.push('/main/target/addCategoryTarget')
    }

    const ruleForm = reactive({
      year: '',
      kpiName:'',
      indicatorName:''
    })

    const handleSearchClick = () => {
      if (props.activeName=='third') {
        emit('handleSearchClick',ruleForm.kpiName)
      }else{
        emit('handleSearchClick',ruleForm.indicatorName)
      }
    }

    const initPage = async () => {
      // let targetYear = cache.getCache('targetYear')
      let year = dayjs(new Date()).format('YYYY')
      // if (targetYear == undefined) {
      //   year = dayjs(new Date()).format('YYYY')
      //   cache.setCache('targetYear', year)
      // } else {
      //   year = targetYear
      // }
      ruleForm.year = year
    }
    initPage()

    const handleYearChange = value => {
      let year = dayjs(value).format('YYYY')
      cache.setCache('targetYear', year)
      emit('refreshPage')
    }

    return {
      handleYearChange,
      handleTargetClick,
      ruleForm,
      handleSearchClick
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(230, 230, 230);

  &:deep(.el-select) {
    margin-left: 10px;
  }

  .add-button {
    margin-left: 10px;
  }

  .left {
    &:deep(.el-select) {
      margin-right: 10px;
    }

    &:deep(.el-checkbox) {
      margin-left: 12px;
    }
  }

  .right {
    display: flex;
    align-items: center;
    color: rgba(42, 130, 228, 1);
    font-size: 14px;
    cursor: pointer;

    img {
      width: 17px;
      height: 17px;
      margin-right: 8px;
    }
  }
}
</style>




