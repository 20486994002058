<template>
  <div class="shop-manage-target">
    <div v-for="item in list" :key="item" :class="item.resultStatus==1?'red':'green'">
      <el-descriptions :title="item.name" :extra="item.resultStatus==1?'未填写':'已完成'" @click="toDetail(item)" :column="4">
        <el-descriptions-item v-if="item.resultStatus==2" label="考核时间：">{{item.createTime}}</el-descriptions-item>
        <el-descriptions-item v-if="item.resultStatus==2" label="考核人数：">{{item.empCount}}</el-descriptions-item>
        <el-descriptions-item v-if="item.resultStatus==2" label="周期类型：">{{item.cycleType==1?'月度':'-'}}</el-descriptions-item>
        <el-descriptions-item label="角色：">{{item.role}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  setup() {
    const router = useRouter()
    const toDetail = (item) =>{
      router.push({
        path: '/main/target/addCategoryTarget',
        query: {
          type:0,
          isEdit:item.resultStatus,
          id:item.id
        }
      })
    }

    return {
      toDetail
    }

  }
})
</script>

<style scoped lang="less">
.shop-manage-target {
  &:deep(.el-descriptions){
    padding: 20px 20px 0px;
    border-bottom: 1px solid #ccc;
    .el-descriptions__body .el-descriptions__table{
      width: 50%;
    }
  }
  .red{
    &:deep(.el-descriptions){
      .el-descriptions__extra{
        color: red;
      }
    }
  }
  .green{
    &:deep(.el-descriptions){
      .el-descriptions__extra{
        color: #67c23a;
      }
    }
  }
}
</style>




